export type LightspeedAccountGetter = (
  callback: (response: LightspeedKSeriesResponse) => void,
) => void;

export type LightspeedAccountPayer = (
  paymentMethodCode: string,
  callback: () => void,
) => void;

export type LightspeedModalCloser = () => void;

export interface LightspeedKSeriesResponse {
  error: LightspeedKSeriesError | null;
  data: {
    fiscalIdentifier: string;
    consumer: LightspeedKSeriesCustomer | null;
    isDraft: boolean;
  };
}

export interface LightspeedKSeriesError {
  message: string;
}

export interface LightspeedKSeriesCustomer {
  firstName: string;
  lastName: string;
  city: string;
  addressLine1: string;
  email: string;
  identifier: string;
  phoneNumber1: string;
  consumer: string;
  zipCode: string;
  addressLine2: string;
}

const dummyLightspeedKSeriesCustomer = (): LightspeedKSeriesCustomer => ({
  firstName: 'Foo',
  lastName: 'Bar',
  city: 'Amsterdam',
  addressLine1: '1',
  email: 'foo@test.com',
  identifier: '1',
  phoneNumber1: '1',
  consumer: '1',
  zipCode: '1',
  addressLine2: '1',
});

const dummyAccountGetter = (): LightspeedAccountGetter => (callback) =>
  // Spread of LightspeedKSeriesCustomer is necessary to make TS linter
  // understand that this object complies with Record<string, string>
  callback({
    data: {
      consumer: { ...dummyLightspeedKSeriesCustomer() },
      fiscalIdentifier: 'A72924.18',
      isDraft: false,
    },
    error: null,
  });

const dummyAccountPayer =
  (): LightspeedAccountPayer =>
  (paymentMethodCode: string, callback: () => void) => {
    // eslint-disable-next-line
    console.info(
      `Paid receipt using payment method code: ${paymentMethodCode}`,
    );
    callback();
  };

export const registerDummyLightspeedLibrary = (): void => {
  const w = window as any;
  w.pos_getCurrentAccount = dummyAccountGetter();
  w.pos_payAccount = dummyAccountPayer();
};

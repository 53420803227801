import { BaseService } from '../../base';
import { CreatorService } from '.';
import {
  Customer,
  Invoice,
  InvoiceReceiver,
  InvoiceTextEmail,
  InvoiceVAT,
  LedgerNumber,
  Product,
  Profile,
} from '@/models';

import {
  ExternalInit,
  LightspeedKSeriesItem,
} from '@/models/external/ExternalInit';

export default class LightspeedKSeriesCreatorService
  extends BaseService
  implements CreatorService
{
  public data: ExternalInit<LightspeedKSeriesItem>;
  private jwt: string;
  private receipt: string;
  private company: number;

  public setJwt(jwt: string): void {
    this.jwt = jwt;
  }

  public setCompany(company: number): void {
    this.company = company;
  }

  private get urlParams() {
    return {
      receipt: this.receipt,
      jwt: this.jwt,
      company: this.company,
    };
  }

  public async setup(
    receipt: string,
    name: string,
    email: string,
  ): Promise<void> {
    this.receipt = receipt;
    name = name.trim();

    const { data } = await this.axios().get(`/external/lskseries/init`, {
      params: { ...this.urlParams, name, email },
    });

    this.data = ExternalInit.deserialize(data.data);
  }

  public async getLogo(logo: string): Promise<any> {
    const { data } = await this.axios().get(
      `/external/lskseries/logo/${logo}`,
      {
        params: this.urlParams,
      },
    );
    return data.data;
  }

  public async listReceivers(): Promise<InvoiceReceiver[]> {
    return this.data?.customers?.options?.map((e) => e.toInvoiceReceiver());
  }

  public async listProfiles(): Promise<Profile[]> {
    return this.data?.profiles?.options ?? [];
  }

  public async searchProducts(keyword: string): Promise<Product[]> {
    void keyword;
    return [];
  }

  public async listLedgerNumbers(): Promise<LedgerNumber[]> {
    return this.data?.ledger?.options ?? [];
  }

  public async listVatCodes(): Promise<InvoiceVAT[]> {
    return this.data?.vat?.options ?? [];
  }

  public async emailText(emailTextId: number): Promise<InvoiceTextEmail> {
    const text = this.data.emailText.options.find(
      (e) => +e.id === +emailTextId,
    );

    if (!text) {
      throw new Error('Emailtext not found');
    }

    return InvoiceTextEmail.fromProfileEmailText(text);
  }

  public async createInvoice(invoice: Invoice): Promise<string> {
    const { data } = await this.axios().post(
      `/external/lskseries`,
      invoice.serialize(),
      {
        params: this.urlParams,
      },
    );
    return data.data;
  }

  public async sendMail(id: string, text: InvoiceTextEmail): Promise<void> {
    await this.axios().post(
      `/external/lskseries/${id}/send/mail`,
      text.serialize(),
      {
        params: this.urlParams,
      },
    );
  }

  public async createCustomer(customer: Customer): Promise<number> {
    const { data } = await this.axios().post(
      `/external/lskseries/customer`,
      customer.serialize(),
      {
        params: this.urlParams,
      },
    );
    return data.data.id;
  }

  public async updateCustomer(customer: Customer): Promise<void> {
    await this.axios().put(
      `/external/lskseries/customer/${customer.id}`,
      customer.serialize(),
      {
        params: this.urlParams,
      },
    );
  }
}































import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import Big from 'big.js';

import { InvoiceCreator } from '@/components/invoicecreator';
import SendInvoiceModal from '@/components/SendInvoiceModal.vue';
import LightspeedKSeriesCreatorService from '@/lib/services/meta/creator/lskseries';
import {
  Invoice,
  InvoiceLine,
  InvoiceStatus,
  InvoiceTextEmail,
} from '@/models/invoice';
import { isProduction, sleep, unwrapError } from '@/lib/helpers';
import InvoiceCreatorConfig from '@/components/invoicecreator/config';
import {
  LightspeedAccountGetter,
  LightspeedAccountPayer,
  LightspeedModalCloser,
  registerDummyLightspeedLibrary,
} from '@/lib/shims/lightspeed-k-series';

const AUTOCLOSE_DELAY = 5000;

/**
 * This view is loaded in an iframe by LS K Series web extension, and it should have
 * access to injected JS context, which gives us additional info about the current
 * receipt opened by a user in their POS system.
 * All methods listed here https://api-portal.lsk.lightspeed.app/guides/web-extensions/webext-functions
 * are injected in the window object, and are available globally.
 * We currently use only pos_getCurrentAccount (to get customer info we cannot get through LS API)
 * and pos_payAccount (to mark receipt as paid as soon as an invoice is created).
 * To aid local development, we have our own registerDummyLightspeedLibrary fn which
 * mimics what LS does in their app. One should just update the @/lib/shims/lightspeed-k-series
 * with some realistic values.
 */
declare var pos_getCurrentAccount: LightspeedAccountGetter;
declare var pos_payAccount: LightspeedAccountPayer;
declare var pos_close: LightspeedModalCloser;

@Component({
  components: {
    InvoiceCreator,
    SendInvoiceModal,
  },
})
export default class LightspeedKSeriesPage extends Vue {
  @Provide() creatorService = new LightspeedKSeriesCreatorService();

  loading: boolean = true;
  errors: string[] = [];
  completed: boolean = false;
  invoice: Invoice = new Invoice();
  showSendModal: boolean = false;

  creatorConfig: InvoiceCreatorConfig = {
    disableVatChecker: true,
    disableIncludeVat: true,
    disableLineDelete: false,
    allowedTypes: [InvoiceStatus.DRAFT, InvoiceStatus.RECURRING],
    ghost: {},
  };

  beforeMount(): void {
    const jwt = this.$route.query.jwt;
    if (!jwt?.length || typeof jwt !== 'string') {
      this.errors.push('Lightspeed jwt not provided');
      return;
    }

    const company = this.$route.query.company;
    if (!company?.length || typeof company !== 'string') {
      this.errors.push('Lightspeed company not provided');
      return;
    }

    this.creatorService.setJwt(jwt);
    this.creatorService.setCompany(+company);
  }

  async mounted(): Promise<void> {
    this.loading = true;
    if (
      typeof pos_payAccount === 'undefined' ||
      typeof pos_getCurrentAccount === 'undefined'
    ) {
      if (isProduction()) {
        this.errors.push('Lightspeed web extension is missing');
        return;
      }
      // this page wasn't loaded via the LS app (which loads the LS js lib)
      // but since it's a dev build - let's load our dummy LS js lib
      registerDummyLightspeedLibrary();
    }
    try {
      pos_getCurrentAccount(async (response) => {
        const data = response.data;
        if (!data || data.isDraft) {
          this.errors.push(
            'This receipt is still a draft, please send the order first.',
          );
          this.loading = false;
          return;
        }
        const id = data.fiscalIdentifier ?? null;
        const firstName = data.consumer?.firstName ?? '';
        const lastName = data.consumer?.lastName ?? '';
        const email = data.consumer?.email ?? '';
        try {
          await this.creatorService.setup(
            id,
            `${firstName} ${lastName}`,
            email,
          );
          await this.prepareInvoice();
        } catch (e) {
          const errorText = (e as any).response?.data?.error?.message;
          if (errorText) {
            this.errors.push(errorText);
          } else {
            this.errors.push(String(e));
          }
          this.errors.push('DEBUG INFO:\n' + JSON.stringify(data));
        } finally {
          this.loading = false;
        }
      });
    } catch (e) {
      this.errors.push((e as any).message);
      // eslint-disable-next-line
      console.error(e);
    }
  }

  async saveInvoice(invoice: Invoice): Promise<void> {
    try {
      const id = await this.creatorService.createInvoice(invoice);
      this.$toaster.success(this.$tc(`messages.success.created.invoice`), id);
      await this.complete();
    } catch (e) {
      this.$toaster.error('Error', this.$tc('messages.error.save.invoice'));
    }
  }

  async finishInvoice(
    type: string,
    invoice: Invoice,
    text: InvoiceTextEmail,
  ): Promise<boolean> {
    try {
      const id = await this.creatorService.createInvoice(invoice);
      if (!id) {
        this.$toaster.error('Error', this.$tc('messages.error.save.invoice'));
        return false;
      }

      await this.creatorService.sendMail(id, text);

      this.$toaster.success(
        this.$tc('messages.success.send.invoice.title'),
        this.$tc('messages.success.send.invoice.body'),
      );

      // IKDEBT means invoice
      pos_payAccount('IKDEBT', async () => {
        await this.complete();
        this.showSendModal = false;
      });
      return true;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.send.invoice'),
        unwrapError(e),
      );
      return false;
    }
  }

  private async complete(): Promise<void> {
    this.completed = true;
    await sleep(AUTOCLOSE_DELAY);
    pos_close();
  }

  private async prepareInvoice() {
    const data = this.creatorService.data;

    this.invoice.includesVat = data.vatInclusive ?? true;
    if (data.currency) {
      this.invoice.meta.currency = data.currency;
    }
    if (data.customers.match) {
      this.invoice.receiver = data.customers.match.toInvoiceReceiver();
    }
    this.creatorConfig.ghost!.customer = {
      name: data.customers.name,
      email: data.customers.email,
      showCreateNotification: !data.customers.match,
    };

    for (const item of data.items) {
      const line = new InvoiceLine(this.invoice.includesVat);

      line.quantity = new Big(item.quantity);
      line.description = item.itemName;
      line.price = new Big(item.unitAmount);
      line.vat =
        data.vat.options.find((e) => e.id === data.vat.match[item.id]) || null;
      const ledger = data.ledger.options.find(
        (e) => e.id === data.ledger.match[item.id],
      );
      line.ledger = ledger ? ledger.toInvoiceLedger() : null;

      if (line.ledger === null && data.ledger.options.length === 1) {
        // if we couldn't match the ledger but there's only one lets just pick that one
        line.ledger = data.ledger.options[0].toInvoiceLedger();
      }

      this.invoice.lines.push(line);
    }
  }
}
